.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10rem; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
    padding-left: 10rem;
  }

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 10rem;
}

.more-btn .more-text{ 
  animation: rotate-more 15s linear infinite;
}

@keyframes rotate-more {
  0%{
    transform: rotate(-360deg);
  }
}