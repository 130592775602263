.navbar-link-green {
    @apply px-3 flex items-center text-greenEllie-dark uppercase hover:opacity-50;
}

.navbar-language-green {
    @apply px-1 flex items-center text-greenEllie-dark uppercase hover:opacity-50;
}

.navbar-link-white {
    @apply px-3 flex items-center text-whiteEllie uppercase hover:opacity-50;
}

.navbar-language-white {
    @apply px-1 flex items-center text-whiteEllie uppercase hover:opacity-50;
}

.navbar-link-white.active::before {
    content: "//";
}
.navbar-link-green.active::before {
    content: "//";
}

.bg-odd{
    animation: fadein 3s;
}

.bg-even{
    animation: fadein 3s;
}

.mobile-menu-fadein{
    animation: fadein-menu 0.5s 0.3s;
    animation-fill-mode: forwards;
}

@keyframes fadein-menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.logo{
    width: 170px;
}
