.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes progressCarousel {
  from {
    width: 0%
  }

  to {
    width: 100%
  }
}

.progress {
  animation-name: progressCarousel;
  animation-duration: 4s;
  width: 0%;
  animation-iteration-count: infinite;
}

@keyframes mowerMotion {
  0% {
    transform: translateY(2px)
  }

  50% {
    transform: translateY(-2px)
  }

  100% {
    transform: translateY(2px)
  }
}

.mower {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: mowerMotion;
}

@keyframes landscapeMotion {
  to {
    background-position: 100% 0%;
  }
}

.container-loading {
  width: 600px;
  height: auto;
}

.landscape {
  height: 400px;
  width: 100%;
  background-image: url('./images/tree.svg');
  background-size: 300px 100%;
  background-repeat: repeat;
  background-position: 0 0;
  animation: shift 4s linear infinite;
}

@keyframes shift {
  to {
    background-position: -100% 0;
  }
}


@keyframes blink {
  50% {
    fill: transparent
  }
}

@keyframes opac {
  0% {
    opacity: 0;
    transform: translateX(100%)
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0;
    transform: translateX(50%)
  }
}

.dot {
  animation: 2s opac infinite;
  fill: #fff;
  animation-delay: 1s
}

.dot:nth-child(2) {
  animation-delay: 500ms
}

.dot:nth-child(3) {
  animation-delay: 0ms
}

@keyframes carouselChange {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}

.carousel {
  animation-name: carouselChange;
  animation-duration: 4.4s;
  animation-iteration-count: infinite;
}

.topo {
  background-image: url('./images/topography.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.st0{
  fill: #fff;
}